.Component {
  display: grid;
  grid-auto-rows: max-content;
  row-gap: 8px;

  .Title {
    font-size: 0.75rem;
    font-weight: bold;
    justify-self: center;
    letter-spacing: -1px;
    text-transform: uppercase;
  }

  .Body {
    text-align: center;
  }
}
