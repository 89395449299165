:root {
  --color-background: #0f0f0f;
  --color-primary: #f4ac45;
  --color-secondary: #1c1c1c;
  --color-sidebar-background-active: #241f19;
  --color-sidebar-background-primary: #000000;
  --color-sidebar-background-secondary: #191919;
  --color-sidebar-primary: #959aa1;
  --color-white: #ffffff;

  --footer-item-padding: 6px;
  --footer-item-radius: 0.5rem;
  --footer-icon-size: 1.25rem;

  --padding-small: 6px;
  --padding-medium: 8px;
}
